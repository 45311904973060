// eslint-disable-next-line @typescript-eslint/no-explicit-any
const locale: { [key: string]: any } = {
  seo: {
    title: 'Hulka Token',
    description: 'Smash into the crypto-verse with HULKA, the memecoin that’s greener than Hulk! Join the fun and rage your way to the moon with our mighty meme power!',
    thumbnailUrl: '',
  },
  hero: {
    title: 'THE HULKA',
    description: 'it represents a leap forward into the meme culture and financial freedom in crypto world.',
    contractAddress: 'Contract Address:',
    marquee: 'the hulka $HulKA the hulka $HulKA the hulka $HulKA the hulka $HulKA the hulka $HulKA the hulka $HulKA the hulka $HulKA the hulka $HulKA the hulka $HulKA the hulka $HulKA the hulka $HulKA the hulka $HulKA',
  },
  about: {
    title: 'About',
    description: 'it represents a leap forward into the meme culture and financial freedom in crypto world. Clearly, the future is for those forward-thinkers who embrace BAPICA, breaking barriers and welcoming a new era in finance and technology.',
  },
  how_to_buy: {
    title: 'How to buy',
    description:'it represents a leap forward into the meme culture and financial freedom in crypto world. ',
    create_wallet: {
      title: '1. Create a wallet with Phantom.',
      description1: 'Visit ',
      description2: 'phantom.app ',
      description3:'and follow the simple steps to create a new account with the Phantom app or browser extension.',
    },
    get_sol: {
      title: '2. Get some $SOL.',
      description: 'Tap the BUY button in the app to purchase Solana, or deposit $SOL to your Phantom wallet from the crypto exchange of your choice.',
    },
    swap_sol: {
      title: '3. Swap $SOL for $PONKE.',
      description: 'Tap the SWAP icon in your Phantom wallet and paste the $PONKE token address. Swap your $SOL for $PONKE.',
    }, 
    hold_sol : {
      title:'4. You are now a $PONKE holder!',
      description: 'Welcome to the #Ponke Army!',
    },
  },
  tokenomics: {
    title: 'Tokenomics',
    description: 'it represents a leap Contract renounced forward into the meme culture',
    liquidity: {
      value: '50% ',
      text: 'liquidity',
    },
    aidrop: {
      value: '30% ',
      text: 'airdrop',
    },
    development: {
      value: '20% ',
      text: 'development',
    },
    total_supply: 'Total Supply',
    supply_value: '100,000,000 $HULKA',
  },
  roadmap: {
    title:'Roadmap',
    description: {
      1: '1',
      1.1: '$HULKA official launch on Solana',
      1.2: 'Just HODL and pamp it',
      2:'2',
      2.1: 'Community airdrops',
      2.2: 'Coinmarkecap and Coingecko listing',
      2.3: 'More exchanges listing',
      2.4: 'Just HODL and pamppp it',
      3: '3',
      3.1: 'Pampbot official launch',
      3.2: 'Buy back and burn mechanism',
      3.3: 'Expansion to other networks',
      3.4: 'Just HODL and pamppppppp it',
    },
  },
  footer: {
    copy_right: '2024 HULKA All Rights Reserved',
  },
  our_partners: {
    title: 'Our partners',
    powered_by: 'Powered by',
  },
}

export default locale
