import Image from 'next/image'

import useTrans from '@/root/src/hooks/use-trans'
import { ImageName, staticImportImage } from '@/root/src/utils/static-import-image'
import { Stack, Typography } from '@mui/material'
import HulkaAboutImage from '../../base/hulka-about-image'

const About = () => {
  const trans = useTrans()

  return (
    <Stack className="bg-primary-bg-light py-20 xl:px-[155px] lg:px-10 px-4 flex lg:flex-row flex-col justify-between items-center gap-[30px]">
      <Stack className="lg:w-[40%] lg:max-w-[40%] w-full max-w-full flex-col justify-start items-start lg:gap-0 gap-4">
        <Image
          src={staticImportImage(ImageName.AboutImage)}
          alt={ImageName.AboutImage}
          className="w-[171px] h-[171px]"
        />
        <Typography className="text-primary-text-dark text-center lg:text-[84px] text-[48px] lg:leading-[117px] leading-[64px] font-bold font-milky-nice">
          {trans.about.title}
        </Typography>
        <Typography className="text-primary-text-dark text-left text-[24px] leading-[40px] font-bold font-milky-nice">
          {trans.about.description}
        </Typography>
      </Stack>
      <Stack
        className="flex justify-start items-center md:w-[60%] md:max-w-[60%] max-h-[400px] h-[400px] mt-[-160px]
      lg:max-h-full lg:h-full lg:mt-0"
      >
        <HulkaAboutImage />
      </Stack>
    </Stack>
  )
}
export default About
