import FooterBackground from '@/assets/footer-background.png'
import BuyHulka from '@/assets/buy-hulka.png'
import FooterMask from '@/assets/footer-mask.png'
import HulkaIcon from '@/assets/hulka-icon.png'
import FooterImage1 from '@/assets/footer-image-1.png'
import FooterImage2 from '@/assets/footer-image-2.png'
import FooterImage3 from '@/assets/footer-image-3.png'
import Solana from '@/assets/solana.png'
import TelegramIcon from '@/assets/telegram-icon.png'
import TwitterIcon from '@/assets/twitter-icon.png'
import DexScreenerIcon from '@/assets/dex-screener-icon.png'
import SolScanIcon from '@/assets/solscan-icon.png'
import CoinmarketcapIcon from '@/assets/coinmarketcap-icon.png'
import CoinGeckoIcon from '@/assets/coingecko-icon.png'
import CryptoRankIcon from '@/assets/cryptorank-icon.png'
import DexToolsIcon from '@/assets/dextools-icon.png'
import BuyHulkaButton from '@/assets/buy-hulka-button.png'
import TwitterButton from '@/assets/twitter-button.png'
import TelegramButton from '@/assets/telegram-button.png'
import CopyIcon from '@/assets/copy-icon.png'
import HeroDivider from '@/assets/hero-divider.png'
import HulkaMain from '@/assets/hulka-main.gif'
import AboutImage from '@/assets/about-image.png'
import HulkaAboutContainer from '@/assets/hulka-about-container.png'
import HulkaAboutMain from '@/assets/hulka-about-main.png'
import HulkaAboutStick from '@/assets/hulka-about-stick.png'
import HulkaAboutSticker from '@/assets/hulka-about-sticker.png'
import Cloud1 from '@/assets/cloud1.png'
import HowToBuy from '@/assets/how-to-buy.png'
import CreatePhantomWallet from '@/assets/create-phantom-wallet.png'
import GetSomeSol from '@/assets/get-some-sol.png'
import SwapSol from '@/assets/swap-sol.png'
import HoldSol from '@/assets/hold-sol.png'
import HulkaAngry from '@/assets/hulka-angry.png'
import Tokenomics from '@/assets/tokenomics.png'
import TokenomicsRed from '@/assets/tokenomics-red.png'
import TokenomicsMobile from '@/assets/tokenomics-mobile.png'
import RoadmapHero from '@/assets/roadmap-hero.png'
import RoadmapImage from '@/assets/roadmap-image.png'
import RoadmapVector from '@/assets/roadmap-vector.png'
import RoadmapIndex from '@/assets/roadmap-index.png'

export const ImageName = {
  FooterBackground: 'footer-background',
  BuyHulka: 'buy-hulka',
  FooterMask: 'footer-mask',
  HulkaIcon: 'hulka-icon',
  FooterImage1: 'footer-image-1',
  FooterImage2: 'footer-image-2',
  FooterImage3: 'footer-image-3',
  Solana: 'solana',
  TelegramIcon: 'telegram-icon',
  TwitterIcon: 'twitter-icon',
  DexScreenerIcon: 'dex-screener-icon',
  SolScanIcon: 'solscan-icon',
  CoinmarketcapIcon: 'coinmarketcap-icon',
  CoinGeckoIcon: 'coingecko-icon',
  CryptoRankIcon: 'cryptorank-icon',
  DexToolsIcon: 'dextools-icon',
  BuyHulkaButton: 'buy-hulka-button',
  TwitterButton: 'twitter-button',
  TelegramButton: 'telegram-button',
  CopyIcon: 'copy-icon',
  HeroDivider: 'hero-divider',
  HulkaMain: 'hulka-main',
  AboutImage: 'about-image',
  HulkaAboutContainer: 'hulka-about-container',
  HulkaAboutMain: 'hulka-about-main',
  HulkaAboutStick: 'hulka-about-stick',
  HulkaAboutSticker: 'hulka-about-sticker',
  Cloud1: 'cloud1',
  HowToBuy: 'how-to-buy',
  CreatePhantomWallet: 'create-phantom-wallet',
  GetSomeSol: 'get-some-sol',
  SwapSol: 'swap-sol',
  HoldSol: 'hold-sol',
  HulkaAngry: 'hulka-angry',
  Tokenomics: 'tokenomics',
  TokenomicsRed: 'tokenomics-red',
  TokenomicsMobile: 'tokenomics-mobile',
  RoadmapHero: 'roadmap-hero',
  RoadmapImage: 'roadmap-image',
  RoadmapVector: 'roadmap-vector',
  RoadmapIndex: 'roadmap-index',
}

export const staticImportImage = (iconName: string) => {
  switch (iconName) {
    case ImageName.FooterBackground:
      return FooterBackground
    case ImageName.BuyHulka:
      return BuyHulka
    case ImageName.FooterMask:
      return FooterMask
    case ImageName.HulkaIcon:
      return HulkaIcon
    case ImageName.FooterImage1:
      return FooterImage1
    case ImageName.FooterImage2:
      return FooterImage2
    case ImageName.FooterImage3:
      return FooterImage3
    case ImageName.Solana:
      return Solana
    case ImageName.TelegramIcon:
      return TelegramIcon
    case ImageName.TwitterIcon:
      return TwitterIcon
    case ImageName.DexScreenerIcon:
      return DexScreenerIcon
    case ImageName.SolScanIcon:
      return SolScanIcon
    case ImageName.CoinmarketcapIcon:
      return CoinmarketcapIcon
    case ImageName.CoinGeckoIcon:
      return CoinGeckoIcon
    case ImageName.CryptoRankIcon:
      return CryptoRankIcon
    case ImageName.DexToolsIcon:
      return DexToolsIcon
    case ImageName.BuyHulkaButton:
      return BuyHulkaButton
    case ImageName.TwitterButton:
      return TwitterButton
    case ImageName.TelegramButton:
      return TelegramButton
    case ImageName.CopyIcon:
      return CopyIcon
    case ImageName.HeroDivider:
      return HeroDivider
    case ImageName.HulkaMain:
      return HulkaMain
    case ImageName.AboutImage:
      return AboutImage
    case ImageName.HulkaAboutContainer:
      return HulkaAboutContainer
    case ImageName.HulkaAboutMain:
      return HulkaAboutMain
    case ImageName.HulkaAboutStick:
      return HulkaAboutStick
    case ImageName.HulkaAboutSticker:
      return HulkaAboutSticker
    case ImageName.Cloud1:
      return Cloud1
    case ImageName.HowToBuy:
      return HowToBuy
    case ImageName.CreatePhantomWallet:
      return CreatePhantomWallet
    case ImageName.GetSomeSol:
      return GetSomeSol
    case ImageName.SwapSol:
      return SwapSol
    case ImageName.HoldSol:
      return HoldSol
    case ImageName.HulkaAngry:
      return HulkaAngry
    case ImageName.Tokenomics:
      return Tokenomics
    case ImageName.TokenomicsRed:
      return TokenomicsRed
    case ImageName.TokenomicsMobile:
      return TokenomicsMobile
    case ImageName.RoadmapHero:
      return RoadmapHero
    case ImageName.RoadmapImage:
      return RoadmapImage
    case ImageName.RoadmapVector:
      return RoadmapVector
    case ImageName.RoadmapIndex:
      return RoadmapIndex
    default:
      return FooterBackground
  }
}
