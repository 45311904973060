import Image from 'next/image'

import useTrans from '@/root/src/hooks/use-trans'
import { ImageName, staticImportImage } from '@/root/src/utils/static-import-image'
import { Stack, Typography } from '@mui/material'
import BuyHulkaButton from '../../base/buy_hulka_button'

const HowToBuy = () => {
  const trans = useTrans()

  return (
    <Stack className="bg-primary-bg-light py-20 xl:px-[155px] lg:px-10 px-4">
      <Stack className="xl:w-[64%] xl:max-w-[64%] lg:w-[76%] lg:max-w-[76%] w-full max-w-full ml-0 lg:ml-[10%] xl:ml-[20%]">
        <Stack className="flex flex-col-reverse lg:flex-row justify-start items-start lg:items-center lg:gap-6 gap-4">
          <Typography className="text-primary-text-dark text-center lg:text-[84px] text-[48px] lg:leading-[117px] leading-[64px] font-bold font-milky-nice">
            {trans.how_to_buy.title}
          </Typography>
          <Image
            src={staticImportImage(ImageName.HowToBuy)}
            alt={ImageName.HowToBuy}
            className="w-[124px] h-[148px] lg:w-[92px] lg:h-[110px]"
          />
        </Stack>
        <Typography className="max-w-[720px] text-primary-text-dark text-left text-[24px] leading-[40px] font-bold font-milky-nice">
          {trans.how_to_buy.description}
        </Typography>
      </Stack>
      <Stack className="flex lg:flex-row flex-col gap-6 my-[64px]">
        <Stack className="relative p-1 bg-gradient-to-b from-[#FFF0DA] to-black rounded-2xl w-full lg:w-[25%]">
          <Stack className="flex-col p-4 gap-4 items-start justify-center bg-white rounded-2xl h-full">
            <Stack className="flex justify-center items-center w-full max-w-full">
              <Image
                src={staticImportImage(ImageName.CreatePhantomWallet)}
                alt={ImageName.HowToBuy}
                className="w-[68px] h-[68px]"
              />
            </Stack>
            <Typography className="text-primary-text-dark text-left text-[24px] leading-[36px] font-bold font-milky-nice">
              {trans.how_to_buy.create_wallet.title}
            </Typography>
            <Typography className="text-primary-text-dark text-left text-[16px] leading-[24px] font-bold font-milky-nice">
              {trans.how_to_buy.create_wallet.description1}
              <Typography
                component="span"
                className="inline text-secondary text-left text-[16px] leading-[24px] font-bold font-milky-nice"
              >
                {trans.how_to_buy.create_wallet.description2}
              </Typography>
              {trans.how_to_buy.create_wallet.description3}
            </Typography>
          </Stack>
        </Stack>
        <Stack className="relative p-1 bg-gradient-to-b from-[#FFF0DA] to-black rounded-2xl w-full lg:w-[25%]">
          <Stack className="flex-col p-4 gap-4 items-start justify-center bg-white rounded-2xl h-full">
            <Stack className="flex justify-center items-center w-full max-w-full">
              <Image
                src={staticImportImage(ImageName.GetSomeSol)}
                alt={ImageName.GetSomeSol}
                className="w-[70px] h-[74px]"
              />
            </Stack>
            <Typography className="text-primary-text-dark text-left text-[24px] leading-[36px] font-bold font-milky-nice">
              {trans.how_to_buy.get_sol.title}
            </Typography>
            <Typography className="text-primary-text-dark text-left text-[16px] leading-[24px] font-bold font-milky-nice">
              {trans.how_to_buy.get_sol.description}
            </Typography>
          </Stack>
        </Stack>
        <Stack className="relative p-1 bg-gradient-to-b from-[#FFF0DA] to-black rounded-2xl w-full lg:w-[25%]">
          <Stack className="flex-col p-4 gap-4 items-start justify-center bg-white rounded-2xl h-full">
            <Stack className="flex justify-center items-center w-full max-w-full">
              <Image
                src={staticImportImage(ImageName.SwapSol)}
                alt={ImageName.SwapSol}
                className="w-[91px] h-[76px]"
              />
            </Stack>
            <Typography className="text-primary-text-dark text-left text-[24px] leading-[36px] font-bold font-milky-nice">
              {trans.how_to_buy.swap_sol.title}
            </Typography>
            <Typography className="text-primary-text-dark text-left text-[16px] leading-[24px] font-bold font-milky-nice">
              {trans.how_to_buy.swap_sol.description}
            </Typography>
          </Stack>
        </Stack>
        <Stack className="relative p-1 bg-gradient-to-b from-[#FFF0DA] to-black rounded-2xl w-full lg:w-[25%]">
          <Stack className="flex-col p-4 gap-4 items-start justify-center bg-white rounded-2xl h-full">
            <Stack className="flex justify-center items-center w-full max-w-full">
              <Image
                src={staticImportImage(ImageName.HoldSol)}
                alt={ImageName.HoldSol}
                className="w-[80px] h-[92px] rotate-[-19.85deg]"
              />
            </Stack>
            <Typography className="text-primary-text-dark text-left text-[24px] leading-[36px] font-bold font-milky-nice">
              {trans.how_to_buy.hold_sol.title}
            </Typography>
            <Typography className="text-primary-text-dark text-left text-[16px] leading-[24px] font-bold font-milky-nice">
              {trans.how_to_buy.hold_sol.description}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack className="lg:flex hidden">
        <BuyHulkaButton />
      </Stack>
    </Stack>
  )
}
export default HowToBuy
