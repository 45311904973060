import { memo } from 'react'

import Image from 'next/image'
import { trackAmplitude } from 'shared-utilities'
import { Stack, Typography } from '@mui/material'
import { ImageName, staticImportImage } from '@/utils/static-import-image'

import { Button } from './Button'

const BuyHulkaButton = () => {
  const textContent = 'Buy Hulka'
  const handleClick = () => {
    trackAmplitude('click_buy_pikat_button', { product: 'hulka' }, { location: 'main_menu' })
  }

  return (
    <Button href="#" target="_blank" rel="noreferrer noopener" onClick={handleClick}>
      <Stack className="relative">
        <Image
          src={staticImportImage(ImageName.BuyHulkaButton)}
          alt="buy_icon"
          className="relative"
          width={285}
          height={113}
        />
        <Typography className="font-bold absolute font-milky-nice text-[32px] leading-[38px] text-white top-8 left-[52px]">
          {textContent}
        </Typography>
      </Stack>
    </Button>
  )
}

export default memo(BuyHulkaButton)
