import useTrans from '@/root/src/hooks/use-trans'
import { ImageName, staticImportImage } from '@/root/src/utils/static-import-image'
import { Stack, Typography } from '@mui/material'
import Image from 'next/image'

const Roadmap = () => {
  const trans = useTrans()

  return (
    <Stack className="relative flex-col justify-between items-center pt-17 pb-28 lg:pt-[116px] lg:pb-32 bg-gradient-to-b from-[#D3EDFF] to-white">
      <Typography
        variant="h4"
        className="hidden lg:block text-center font-milky-nice text-[#171010] mb-[30px] lg:text-[84px] lg:leading-[114px] text-[48px] leading-[64px]"
      >
        {trans.roadmap.title}
      </Typography>
      <Image
        src={staticImportImage(ImageName.RoadmapVector)}
        alt={ImageName.RoadmapVector}
        className="w-[209px] h-[21px] lg:mt-8 mt-[86px] lg:mb-[54px] mb-[16px]"
      />
      <Typography
        variant="h4"
        className="block lg:hidden text-center font-milky-nice text-[#171010] mb-[30px] lg:text-[84px] lg:leading-[114px] text-[48px] leading-[64px]"
      >
        {trans.roadmap.title}
      </Typography>
      <Stack className="flex lg:flex-row flex-col">
        <Stack className="flex-col p-4 justify-start">
          <Stack className="flex relative w-fit items-center ml-10">
            <Image
              src={staticImportImage(ImageName.RoadmapIndex)}
              alt={ImageName.RoadmapIndex}
              className="absolute w-[72px] h-[63px]"
            />
            <Typography
              variant="h4"
              className="z-10 text-center font-milky-nice text-[#171010] text-[52px] leading-[62px]"
            >
              {trans.roadmap.description[1]}
            </Typography>
          </Stack>
          <ul className="list-disc">
            <li>
              <Typography className="mb-4 text-[#171010] text-[14px] leading-[20px] font-milky-nice">
                {trans.roadmap.description[1.1]}
              </Typography>
            </li>
            <li>
              <Typography className="mb-4 text-[#171010] text-[14px] leading-[20px] font-milky-nice">
                {trans.roadmap.description[1.2]}
              </Typography>
            </li>
          </ul>
        </Stack>
        <Stack className="flex-col p-4">
          <Stack className="flex relative w-fit items-center ml-10">
            <Image
              src={staticImportImage(ImageName.RoadmapIndex)}
              alt={ImageName.RoadmapIndex}
              className="absolute w-[72px] h-[63px]"
            />
            <Typography
              variant="h4"
              className="z-10 text-center font-milky-nice text-[#171010] text-[52px] leading-[62px]"
            >
              {trans.roadmap.description[2]}
            </Typography>
          </Stack>
          <ul className="list-disc">
            <li>
              <Typography className="mb-4 text-[#171010] text-[14px] leading-[20px] font-milky-nice">
                {trans.roadmap.description[2.1]}
              </Typography>
            </li>
            <li>
              <Typography className="mb-4 text-[#171010] text-[14px] leading-[20px] font-milky-nice">
                {trans.roadmap.description[2.2]}
              </Typography>
            </li>
            <li>
              <Typography className="mb-4 text-[#171010] text-[14px] leading-[20px] font-milky-nice">
                {trans.roadmap.description[2.3]}
              </Typography>
            </li>
            <li>
              <Typography className="mb-4 text-[#171010] text-[14px] leading-[20px] font-milky-nice">
                {trans.roadmap.description[2.4]}
              </Typography>
            </li>
          </ul>
        </Stack>
        <Stack className="flex-col p-4">
          <Stack className="flex relative w-fit items-center ml-10">
            <Image
              src={staticImportImage(ImageName.RoadmapIndex)}
              alt={ImageName.RoadmapIndex}
              className="absolute w-[72px] h-[63px]"
            />
            <Typography
              variant="h4"
              className="z-10 text-center font-milky-nice text-[#171010] text-[52px] leading-[62px]"
            >
              {trans.roadmap.description[3]}
            </Typography>
          </Stack>
          <ul className="list-disc">
            <li>
              <Typography className="mb-4 text-[#171010] text-[14px] leading-[20px] font-milky-nice">
                {trans.roadmap.description[3.1]}
              </Typography>
            </li>
            <li>
              <Typography className="mb-4 text-[#171010] text-[14px] leading-[20px] font-milky-nice">
                {trans.roadmap.description[3.2]}
              </Typography>
            </li>
            <li>
              <Typography className="mb-4 text-[#171010] text-[14px] leading-[20px] font-milky-nice">
                {trans.roadmap.description[3.3]}
              </Typography>
            </li>
            <li>
              <Typography className="mb-4 text-[#171010] text-[14px] leading-[20px] font-milky-nice">
                {trans.roadmap.description[3.4]}
              </Typography>
            </li>
          </ul>
        </Stack>
      </Stack>
      <Stack className="relative lg:absolute right-[0%] top-[-5%] w-[500px] h-[500px]">
        <Image
          src={staticImportImage(ImageName.RoadmapHero)}
          alt={ImageName.RoadmapHero}
          className="absolute w-[282px] h-[354px] rolate-[19.19deg] bottom-[18%] right-[20%]"
        />
        <Image
          src={staticImportImage(ImageName.RoadmapImage)}
          alt={ImageName.RoadmapImage}
          className="absolute w-[500px] h-[500px] bottom-0 right-0"
        />
      </Stack>
    </Stack>
  )
}

export default Roadmap
