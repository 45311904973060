import { useEffect } from 'react'

import { trackAmplitude } from 'shared-utilities'
import {
  Hero,
  About,
  HowToBuy,
  Tokenomics,
  Roadmap,
  OurPartners,
} from '@/components/home/components'

const Home = () => {
  useEffect(() => {
    trackAmplitude('visit_home_page', { product: 'hulka' })
  }, [])

  return (
    <>
      <Hero />
      <About />
      <HowToBuy />
      <Tokenomics />
      <Roadmap />
      <OurPartners />
    </>
  )
}

export default Home
