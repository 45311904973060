import useTrans from '@/root/src/hooks/use-trans'
import Image from 'next/image'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import { ImageName, staticImportImage } from '../../utils/static-import-image'

const Footer = () => {
  const trans = useTrans()
  return (
    <Box>
      <Stack className="w-full max-w-full p-0 my-0 relative">
        <Stack className="flex justify-center items-center h-full">
          <Image
            src={staticImportImage(ImageName.FooterBackground)}
            alt={ImageName.FooterBackground}
            className="lg:w-full lg:h-full max-w-full max-h-full object-cover"
          />
        </Stack>
        <Image
          src={staticImportImage(ImageName.FooterMask)}
          alt={ImageName.FooterMask}
          className="w-full h-full absolute top-0 left-0 z-40"
        />
        <Image
          src={staticImportImage(ImageName.HulkaIcon)}
          alt={ImageName.HulkaIcon}
          className="lg:w-[269px] lg:h-[269px] w-[154px] h-[154px] absolute top-[5%] lg:left-[18%] left-[10%]"
        />
        <Image
          src={staticImportImage(ImageName.FooterImage1)}
          alt={ImageName.FooterImage1}
          className="lg:w-[245px] lg:h-[296px] w-[170px] h-[196px] absolute lg:top-[45%] top-[36%] lg:left-[24%] left-[6%]"
        />
        <Image
          src={staticImportImage(ImageName.FooterImage2)}
          alt={ImageName.FooterImage2}
          className="lg:w-[217px] lg:h-[270px] w-[158px] h-[179px] absolute top-[52%] lg:right-[27%] right-[7%]"
        />
        <Image
          src={staticImportImage(ImageName.FooterImage3)}
          alt={ImageName.FooterImage3}
          className="lg:w-[229px] lg:h-[275px] w-[160px] h-[182px] absolute top-[24%] lg:left-[44%] left-[50%]"
        />
        <Button
          href="#"
          target="_blank"
          rel="noreferrer noopener"
          className="absolute lg:top-[10%] top-[2%] lg:right-[10%] right-[6%] z-50"
        >
          <Image
            src={staticImportImage(ImageName.BuyHulka)}
            alt={ImageName.BuyHulka}
            className="absolute lg:w-[249px] lg:h-[249px] w-[180px] h-[180px] lg:top-[10%] top-[2%] lg:right-[10%] right-[6%] rotate-[15deg] z-50"
          />
        </Button>
        <Button href="#" target="_blank" rel="noreferrer noopener">
          <Image
            src={staticImportImage(ImageName.BuyHulka)}
            alt={ImageName.BuyHulka}
            className="lg:w-[353px] lg:h-[353px] w-[292px] h-[292px] absolute bottom-0 left-0 z-50"
          />
        </Button>
      </Stack>
      <Container className="w-full max-w-full bg-black flex items-center justify-center py-4">
        <Typography className="font-inter text-4 leading-6 text-white">
          {trans.footer.copy_right}
        </Typography>
      </Container>
    </Box>
  )
}

export default Footer
