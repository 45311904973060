import useTrans from '@/root/src/hooks/use-trans'
import { ImageName, staticImportImage } from '@/root/src/utils/static-import-image'
import { Container, Link, ListItem, Stack, Typography } from '@mui/material'
import Image from 'next/image'
const defaultMenus = [
  {
    link: '',
    icon: ImageName.TelegramIcon,
  },
  {
    link: '',
    icon: ImageName.TwitterIcon,
  },
  {
    link: '',
    icon: ImageName.DexScreenerIcon,
  },
  {
    link: '',
    icon: ImageName.SolScanIcon,
  },
  {
    link: '',
    icon: ImageName.CoinmarketcapIcon,
  },
  {
    link: '',
    icon: ImageName.CoinGeckoIcon,
  },
  {
    link: '',
    icon: ImageName.CryptoRankIcon,
  },
  {
    link: '',
    icon: ImageName.DexToolsIcon,
  },
]

const OurParters = () => {
  const trans = useTrans()

  return (
    <Stack className="flex-col justify-between items-center pt-7 pb-28 lg:pt-11 lg:pb-32 bg-gradient-to-b from-white to-[#272134]">
      <Typography
        variant="h4"
        className="text-center font-milky-nice text-[#171010] mb-[30px] lg:text-[84px] lg:leading-[114px] text-[48px] leading-[64px]"
      >
        {trans.our_partners.title}
      </Typography>
      <Container className="flex flex-col lg:flex lg:flex-row gap-6 justify-center items-center mb-[56px]">
        <Typography
          variant="h4"
          className="text-center text-[#272328] w-fit text-[30px] leading-[38px]"
        >
          {trans.our_partners.powered_by}
        </Typography>
        <Image
          src={staticImportImage(ImageName.Solana)}
          alt={ImageName.Solana}
          className="w-[198px] h-[37px]"
        />
      </Container>
      <Container className="grid lg:grid-cols-4 grid-cols-2 lg:gap-10 gap-4">
        {defaultMenus.map((menu, index) => (
          <ListItem
            key={index}
            role="none"
            className="w-full py-4 bg-white rounded-lg px-6 flex justify-center border-solid border-4 border-black"
          >
            <Link href={menu.link} target="_blank" rel="noopener noreferrer">
              <Image
                src={staticImportImage(menu.icon)}
                alt={menu.icon}
                className="w-[97px] h-[97px]"
              />
            </Link>
          </ListItem>
        ))}
      </Container>
    </Stack>
  )
}

export default OurParters
