import Image from 'next/image'

import useTrans from '@/root/src/hooks/use-trans'
import { ImageName, staticImportImage } from '@/root/src/utils/static-import-image'
import { Stack, Typography } from '@mui/material'

const Tokenomics = () => {
  const trans = useTrans()

  return (
    <Stack className="bg-primary-bg-light pb-20 lg:px-[155px] px-4">
      <Image
        src={staticImportImage(ImageName.HulkaAngry)}
        alt={ImageName.HulkaAngry}
        className="block lg:hidden w-[170px] h-[175px] rotate-[-11.12deg]"
      />
      <Stack className="flex items-center">
        <Stack className="flex justify-between lg:items-center items-start">
          <Typography className="text-primary-text-dark text-center lg:text-[84px] text-[48px] lg:leading-[117px] leading-[64px] font-bold font-milky-nice">
            {trans.tokenomics.title}
          </Typography>
          <Typography className="w-full max-w-full lg:w-[60%] lg:max-w-[60%] text-primary-text-dark text-left lg:text-center text-[24px] leading-[40px] font-bold font-milky-nice">
            {trans.tokenomics.description}
          </Typography>
        </Stack>
      </Stack>
      <Stack className="flex justify-center h-full items-center relative mt-20">
        <Stack className="flex justify-center items-center h-full relative">
          <Image
            src={staticImportImage(ImageName.HulkaAngry)}
            alt={ImageName.HulkaAngry}
            className="absolute hidden lg:block w-[354px] h-[366px] top-[-40%] left-[-12%]"
          />
          <Image
            src={staticImportImage(ImageName.Tokenomics)}
            alt={ImageName.Tokenomics}
            className="lg:block hidden w-full h-full max-w-full max-h-full object-cover"
          />
          <Image
            src={staticImportImage(ImageName.TokenomicsMobile)}
            alt={ImageName.TokenomicsMobile}
            className="block lg:hidden w-[100vw] h-full max-w-[100vw] max-h-full object-cover"
          />
          <Stack className="absolute flex justify-between gap-8">
            <Stack className="flex relative justify-center items-center">
              <Image
                src={staticImportImage(ImageName.TokenomicsRed)}
                alt={ImageName.TokenomicsRed}
                className="lg:w-[551px] h-[193px] w-[110vw]"
              />
              <Stack className="absolute flex">
                <Typography className="text-[#FBBFBF] text-center lg:text-[20px] text-[28px] lg:leading-[48px] leading-[40px] font-bold font-milky-nice">
                  {trans.tokenomics.total_supply}
                </Typography>
                <Typography className="text-white text-center lg:text-[48px] text-[32px] lg:leading-[48px] leading-[40px] font-bold font-milky-nice">
                  {trans.tokenomics.supply_value}
                </Typography>
              </Stack>
            </Stack>
            <Stack className="flex-col lg:flex-row items-center lg:gap-20 gap-10">
              <Stack className="flex justify-center">
                <Typography className="text-primary-text-dark text-center lg:text-[48px] text-[40px] lg:leading-[48px] leading-[40px] font-bold font-milky-nice">
                  {trans.tokenomics.liquidity.value}
                  <Typography
                    component="span"
                    className="inline text-primary-text-dark text-center lg:text-[20px] text-[20px] lg:leading-[48px] leading-[40px] font-bold font-milky-nice"
                  >
                    {trans.tokenomics.liquidity.text}
                  </Typography>
                </Typography>
              </Stack>
              <Stack>
                <Typography className="text-primary-text-dark text-center lg:text-[48px] text-[40px] lg:leading-[48px] leading-[40px] font-bold font-milky-nice">
                  {trans.tokenomics.aidrop.value}
                  <Typography
                    component="span"
                    className="inline text-primary-text-dark text-center lg:text-[20px] text-[20px] lg:leading-[48px] leading-[40px] font-bold font-milky-nice"
                  >
                    {trans.tokenomics.aidrop.text}
                  </Typography>
                </Typography>
              </Stack>
              <Stack>
                <Typography className="text-primary-text-dark text-center lg:text-[48px] text-[40px] lg:leading-[48px] leading-[40px] font-bold font-milky-nice">
                  {trans.tokenomics.development.value}
                  <Typography
                    component="span"
                    className="inline text-primary-text-dark text-center lg:text-[20px] text-[20px] lg:leading-[48px] leading-[40px] font-bold font-milky-nice"
                  >
                    {trans.tokenomics.development.text}
                  </Typography>
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
export default Tokenomics
