import Image from 'next/image'
import { ImageName, staticImportImage } from '@/root/src/utils/static-import-image'
import { Stack } from '@mui/material'
const HulkaAboutImage = () => {
  return (
    <Stack className="relative w-[706px] h-[571px] lg:scale-[0.75] md:scale-[0.5] sm:scale-[0.4] scale-[0.4]">
      <Image
        src={staticImportImage(ImageName.HulkaAboutContainer)}
        alt={ImageName.HulkaAboutContainer}
        className="w-[706px] h-[571px]"
      />
      <Image
        src={staticImportImage(ImageName.HulkaAboutMain)}
        alt={ImageName.HulkaAboutMain}
        className="absolute z-10 w-[669px] h-[441px] top-[15%] left-[1%] rotate-[1.57deg]"
      />
      <Image
        src={staticImportImage(ImageName.HulkaAboutStick)}
        alt={ImageName.HulkaAboutStick}
        className="absolute z-10 w-[218px] h-[170px] top-[-12%] left-[36%]"
      />
      <Image
        src={staticImportImage(ImageName.HulkaAboutSticker)}
        alt={ImageName.HulkaAboutSticker}
        className="absolute z-10 w-[194px] h-[203px] bottom-[-10%] right-[-15%]"
      />
    </Stack>
  )
}

export default HulkaAboutImage
