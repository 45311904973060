import Image from 'next/image'

import useTrans from '@/root/src/hooks/use-trans'
import { motion } from 'framer-motion'
import { ImageName, staticImportImage } from '@/root/src/utils/static-import-image'
import { Button, Link, Stack, Typography } from '@mui/material'
import BuyHulkaButton from '../../base/buy_hulka_button'
import { marquee } from 'shared-utilities'

const Hero = () => {
  const trans = useTrans()
  const onCopy = () => {
    navigator.clipboard.writeText(process.env.NEXT_PUBLIC_HULKA_TOKEN_ADDRESS || '')
  }

  return (
    <Stack className="bg-primary-bg-light pt-28 relative">
      <Stack className="absolute  overflow-x-hidden flex items-center justify-center top-[-32px] left-[-24px] h-[88px] bg-black w-[110%] rotate-[-2.19deg]">
        <motion.div
          initial="hidden"
          animate="animate"
          className="relavtive"
          variants={marquee({ offset: -1200, duration: 20 })}
        >
          <Typography className="inline-block w-max max-w-max text-secondary text-center text-[33px] leading-[39px]  font-bold font-milky-nice">
            {trans.hero.marquee}
          </Typography>
        </motion.div>
      </Stack>
      <Typography
        className="text-white text-center lg:text-[124px] text-[84px] lg:leading-[132px] leading-[100px] font-bold font-milky-nice z-10"
        sx={{
          '-webkit-text-stroke': '1px rgba(63, 61, 57, 1)',
        }}
      >
        {trans.hero.title}
      </Typography>
      <Stack className="flex lg:flex-row flex-col justify-cnter items-center gap-[30px]">
        <Stack className="lg:w-[50%] lg:max-w-[50%] md:max-h-full md:h-full flex justify-center items-center relative">
          <Image
            src={staticImportImage(ImageName.HulkaMain)}
            alt={ImageName.HulkaMain}
            className="lg:w-[660px]
          w-[330px] lg:h-[675px] h-[337px] z-50"
          />
          <Image
            src={staticImportImage(ImageName.Cloud1)}
            alt={ImageName.Cloud1}
            className="absolute lg:w-[881px] lg:h-[397px]
          w-[597px] h-[269px] top-[-30%]"
          />
        </Stack>
        <Stack className="flex justify-start px-10">
          <Typography className="lg:mt-20 mt-0 lg:max-w-[400px] max-w-full text-center lg:text-left font-milky-nice text-[#3F3D39] text-[24px] leading-10">
            {trans.hero.description}
          </Typography>
          <div className="flex lg:flex-row flex-col justify-start items-center gap-5">
            <BuyHulkaButton />
            <div className="flex gap-6">
              <Button>
                <Image
                  src={staticImportImage(ImageName.TwitterButton)}
                  alt={ImageName.TwitterButton}
                  className="w-[72px] h-[64px]"
                />
              </Button>
              <Button>
                <Image
                  src={staticImportImage(ImageName.TelegramButton)}
                  alt={ImageName.TelegramButton}
                  className="w-[72px] h-[64px]"
                />
              </Button>
            </div>
          </div>
          <Stack>
            <Typography className="text-[14px] text-[#3F3D3999] font-milky-nice">
              {trans.hero.contractAddress}
            </Typography>
            <Typography className="font-bold text-base break-words">
              <Link
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary-text-dark no-underline"
              >
                {process.env.NEXT_PUBLIC_HULKA_TOKEN_ADDRESS}
              </Link>
            </Typography>
            <Image
              src={staticImportImage(ImageName.CopyIcon)}
              width={28}
              alt={ImageName.CopyIcon}
              className="hover:cursor-pointer"
              onClick={onCopy}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack className="mt-20">
        <Image
          src={staticImportImage(ImageName.HeroDivider)}
          className="w-full h-[32px] max-w-full max-h-[32px] object-cover"
          alt={ImageName.HeroDivider}
        />
      </Stack>
    </Stack>
  )
}
export default Hero
